import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

export function TitleAndMetaTags({
  url = false,
  pathname = '',
  title = 'Pop Crypt',
  description = '',
  image = false,
  keywords = ['Pop Crypt', 'A. G. Cook', 'PC music'],
}) {
  const { config } = useStaticQuery(graphql`
    query TitleAndMetaTags {
      config: configYaml {
        siteUrl
      }
    }
  `)

  return (
    <Helmet>
      <title>{title}</title>

      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />

      <meta name="description" content={'ARCHIVE'} />
      <meta name="keywords" content={keywords.join()} />

      <meta property="og:title" content={'Pop Crypt'} />
      <meta property="og:description" content={'ARCHIVE'} />
      <meta property="og:image" content={`https://popcry.pt/assets/social-2.jpg`} />
      <meta property="og:url" content={'https://popcry.pt'} />
      <meta property="og:type" content="website"></meta>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://popcry.pt/assets/social-2.jpg" />
    </Helmet>
  )
}

export const query = graphql`
  fragment PageMetaFragmentMarkdown on MarkdownRemark {
    frontmatter {
      title
      keywords
      description
      path
    }
  }
`
