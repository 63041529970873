import styled from 'styled-components'

import { media } from '../styles/media'

const HeadingCommons = `
  display: block;
  width: 100%;
  margin: 0.25em 0;
  padding: 0 20px;
`

export const H1 = styled.h1`
  ${HeadingCommons}
  color: ${({theme, color}) => color ? theme.colors[color] : 'inherit'};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  font-size: 3.5em;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  font-family: 'TIMES';
  ${media.largePhone`
    font-size: 2em;
  `}
`

export const H2 = styled.h2`
  ${HeadingCommons} font-size: 2.5em;
  color: ${({theme, color}) => color ? theme.colors[color] : 'inherit'};
  font-weight: ${(props) => (props.bold ? '500' : '100')};
  text-align: ${(props) => (props.align ? props.align : 'center')};

  ${media.largePhone`
    font-size: 2em;
  `};
`

export const H3 = styled.h3`
  ${HeadingCommons} font-size: 1.5em;
  color: ${({theme, color}) => color ? theme.colors[color] : 'inherit'};
  font-weight: ${(props) => (props.bold ? '700' : '100')};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  
  ${media.largePhone`
    font-size: 1.25em;
  `};
`

export const H4 = styled.h4`
  ${HeadingCommons} font-size: 1.25em;
  color: ${({theme, color}) => color ? theme.colors[color] : 'inherit'};
  font-weight: ${(props) => (props.bold ? '700' : '100')};
  text-align: ${(props) => (props.align ? props.align : 'center')};

  ${media.largePhone`
    font-size: 1em;
  `};
`


export const HugeHeading = styled(H1)`
  ${HeadingCommons}
  font-size: 4.5em;
  font-family: 'TIMES';
  

  ${media.largePhone`
    font-size: 3.5em;
  `}
`