import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import CheapSansRegularOTF from './fonts/cheap.otf'
import CheapSansRegularWOFF from './fonts/cheap.woff'
import CheapSansRegularWOFF2 from './fonts/cheap.woff2'

import TIMES from './fonts/TIMES.otf'
import ARIALBDSEMIBOLD from './fonts/ARIALBDSEMIBOLD.otf'
import ARIALBDBOLD from './fonts/ARIALBDBOLD.otf'

export const fonts = `
  @font-face {
    font-family: "cheap-pine-sans";
    src:url(${CheapSansRegularWOFF2}) format("woff2"),url(${CheapSansRegularWOFF}) format("woff"),url(${CheapSansRegularOTF}) format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family: "TIMES";
    src:url(${TIMES}) format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family: "ARIALBDSEMIBOLD";
    src:url(${ARIALBDSEMIBOLD}) format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family: "ARIALBDBOLD";
    src:url(${ARIALBDBOLD}) format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${fonts}

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html {
    min-height: 100%;
    box-sizing: border-box;
  }

  iframe {
    overflow: hidden;
  }
  body {
    color: #1f1f1f;
    background: black;
    background-size: cover;
    max-width: 100vw;
    margin: 0;

    font-weight: 100;
    font-family: 'ARIALBDSEMIBOLD';
  }

  #___gatsby, #___gatsby > * {
      min-height: 100vh;
      max-width: 100vw;
      overflow-x: hidden;
      position: relative;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }


  img {
    max-width: 100vw;
  }
`
