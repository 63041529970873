import React from 'react'
import './RainingText.css'

  export const RainingText = () =>
    <div className="rainingtext">
      <span snd="┋" thrd="┋">┋</span>
      <span snd="├" thrd="├">├</span>
      <span snd="┰" thrd="┰">┰</span>
      <span snd="╠" thrd="╠">╠</span>
      <span snd="╰" thrd="╰">╰</span>
      <span snd="}" thrd="}">}</span>
      <span snd="▜" thrd="▜">▜ </span>
      <span snd="░" thrd="░">░</span>
      <span snd="┋" thrd="┋">┋</span>
      <span snd="├" thrd="├">├</span>
      <span snd="┰" thrd="┰">┰</span>
      <span snd="╠" thrd="╠">╠</span>
      <span snd="╰" thrd="╰">╰</span>
      <span snd="}" thrd="}">}</span>
      <span snd="▜" thrd="▜">▜ </span>
      <span snd="░" thrd="░">░</span>
      <span snd="┋" thrd="┋">┋</span>
      <span snd="├" thrd="├">├</span>
      <span snd="┰" thrd="┰">┰</span>
      <span snd="╠" thrd="╠">╠</span>
      <span snd="╰" thrd="╰">╰</span>
      <span snd="}" thrd="}">}</span>
      <span snd="▜" thrd="▜">▜ </span>
      <span snd="░" thrd="░">░</span>
      <span snd="┋" thrd="┋">┋</span>
      <span snd="├" thrd="├">├</span>
      <span snd="┰" thrd="┰">┰</span>
      <span snd="╠" thrd="╠">╠</span>
      <span snd="╰" thrd="╰">╰</span>
      <span snd="}" thrd="}">}</span>
      <span snd="▜" thrd="▜">▜ </span>
      <span snd="░" thrd="░">░</span>
    </div>




