import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'


import { theme } from '../site'
import { GlobalStyle } from '../styles/globals'

import { RainingText } from '../containers/RainingText';


export const Layout = ({ location, children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color={theme.colors.base} />
        <meta name="msapplication-TileColor" content={theme.colors.base} />
        <meta name="theme-color" content={theme.colors.base} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <main>{children}</main>
          <RainingText/>
          
        </React.Fragment>
      </ThemeProvider>
    </React.Fragment>
  )
}
